<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">用户管理</a></li>
            <li class="breadcrumb-item active" id="title3">用户详情</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">用户详情</h4>
      </div>
    </div>
    <el-form :model="formData" label-width="20%" align="left">
      <el-form-item label="用户ID">
        {{ formData.mid }}</el-form-item>
      <el-form-item label="微信昵称">
        {{ formData.unickname }}</el-form-item>
      <el-form-item label="微信头像" prop="uavatar">
        <img :src="formData.uavatar" style="width: 150px;height: 150px" />
      </el-form-item>
      <el-form-item label="姓名">
        {{ formData.uname }}</el-form-item>
      <el-form-item label="性别">
        {{ formData.sex }}</el-form-item>
      <!-- <el-form-item label="学校">
        {{ formData.uschool }}</el-form-item>
      <el-form-item label="班级">
        {{ formData.uclass }}</el-form-item>
      <el-form-item label="学号">
        {{ formData.ustudentid }}</el-form-item> -->
      <el-form-item label="手机号码">
        {{ formData.uphone }}</el-form-item>
      <el-form-item label="联系地址">
        {{ formData.uaddress }}</el-form-item>
      <el-form-item label="碳积分">
        {{ formData.mval }}</el-form-item>
      <el-form-item label="积分">
        {{ formData.msc }}</el-form-item>
      <el-form-item label="用户状态">
        {{ formData.mflag }}</el-form-item>
      <el-form-item label="注册时间">
        {{ formData.uregtime }}</el-form-item>
      <el-form-item>
        <el-button type="info" size="small" @click="back" icon="el-icon-back">返 回</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>
<script>

import request, { base } from "../../../utils/http";
export default {
  name: 'MembersDetail',
  components: {
  },
  data() {
    return {
      id: '',
      formData: {}, //表单数据         

    };
  },
  created() {
    this.id = this.$route.query.id; //获取参数
    this.getDatas();
  },


  methods: {

    //获取列表数据
    getDatas() {
      let para = {
      };
      this.listLoading = true;
      let url = base + "/members/get?id=" + this.id;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;
      });
    },

    // 返回
    back() {
      //返回上一页
      this.$router.go(-1);
    },

  },
}

</script>
<style scoped></style>
 

